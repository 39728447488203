export enum LoginActionTypes {
  SIGNUP_EMAIL_REQUEST = 'SIGNUP_EMAIL_REQUEST',
  SIGNUP_PASSWORD_REQUEST = 'SIGNUP_PASSWORD_REQUEST',
  SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
  SIGNUP_FAILED = 'SIGNUP_FAILED',
  SIGNUP_CONFIRM_CODE_FAILED = 'SIGNUP_CONFIRM_CODE_FAILED',
  SIGNUP_CONFIRM_CODE = 'SIGNUP_CONFIRM_CODE',
  SIGNIN_REQUEST = 'SIGNIN_REQUEST',
  SIGNIN_SUCCEEDED = 'SIGNIN_SUCCEEDED',
  SIGNIN_FAILED = 'SIGIN_FAILED',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
  SEND_CODE_FOR_NEW_PASSWORD_REQUEST = 'SEND_CODE_FOR_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCEEDED = 'SET_NEW_PASSWORD_SUCCEEDED',
  SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED',
  INITIAL_FORM = 'INITIAL_FORM',
  EMAIL_REGISTRATION_FORM = 'EMAIL_REGISTRATION_FORM',
  PASSWORD_REGISTRATION_FORM = 'PASSWORD_REGISTRATION_FORM',
  CODE_REGISTRATION_FORM = 'CODE_REGISTRATION_FORM',
  LOGIN_FORM = 'LOGIN_FORM',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  FORGOT_PASSWORD_FORM = 'FORGOT_PASSWORD_FORM',
  SEND_CODE_FOR_NEW_PASSWORD_FORM = 'SEND_CODE_FOR_NEW_PASSWORD_FORM',
  SET_STEP = 'SET_STEP',
}

export enum LoginSteps {
  INITIAL_FORM = 'INITIAL_FORM',
  REGISTRATION_EMAIL_FORM = 'REGISTRATION_EMAIL_FORM',
  REGISTRATION_PASSWORD_FORM = 'REGISTRATION_PASSWORD_FORM',
  REGISTRATION_CODE_FORM = 'REGISTRATION_CODE_FORM',
  LOGIN_FORM = 'LOGIN_FORM',
  FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL',
  SEND_CODE_FOR_NEW_PASSWORD_MODAL = 'SEND_CODE_FOR_NEW_PASSWORD_MODAL',
  SET_NEW_PASSWORD_FORM = 'SET_NEW_PASSWORD_FORM',
}

export type LoginAction =
  | SignupEmailRequest
  | SignupPasswordRequest
  | SignupConfirmCode
  | SignupConfirmCodeFailed
  | SignupSucceeded
  | SignupFailed
  | SigninRequest
  | SigninSucceeded
  | SigninFailed
  | ForgotPassword
  | ForgotPasswordForm
  | ForgotPasswordFailed
  | SendCodeForNewPassword
  | SetNewPassword
  | SetNewPasswordSucceeded
  | SignOutRequest
  | SignOutSucceeded
  | SetStep
  | InitialForm
  | EmailRegistrationForm
  | PasswordRegistrationForm
  | CodeRegistrationForm
  | CodeRegistrationForm
  | LoginForm
  | OpenModal
  | CloseModal
  | SendCodeForNewPasswordModal;

export interface SignupEmailRequest {
  type: LoginActionTypes.SIGNUP_EMAIL_REQUEST;
  username: string;
}
export interface SignupPasswordRequest {
  type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST;
  username: string;
  password: string;
  passwordRepeat: string;
}
export interface SignupConfirmCode {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE;
  username: string;
  confirmCode: string;
}
export interface SignupSucceeded {
  type: LoginActionTypes.SIGNUP_SUCCEEDED;
  result: {};
}
export interface SignupFailed {
  type: LoginActionTypes.SIGNUP_FAILED;
  error: Error;
}
export interface SignupConfirmCodeFailed {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED;
  error: Error;
}
export interface SigninRequest {
  type: LoginActionTypes.SIGNIN_REQUEST;
  username: string;
  password: string;
}
export interface SigninSucceeded {
  type: LoginActionTypes.SIGNIN_SUCCEEDED;
  result: {};
}
export interface SigninFailed {
  type: LoginActionTypes.SIGNIN_FAILED;
  error: Error;
}
export interface ForgotPassword {
  type: LoginActionTypes.FORGOT_PASSWORD_REQUEST;
  username: string;
}
export interface ForgotPasswordFailed {
  type: LoginActionTypes.FORGOT_PASSWORD_FAILED;
  error: Error;
}
export interface SendCodeForNewPassword {
  type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_REQUEST;
  confirmCode: string;
}
export interface SetNewPassword {
  type: LoginActionTypes.SET_NEW_PASSWORD_REQUEST;
  username: string;
  confirmCode: string;
  newPassword: string;
}
export interface SetNewPasswordSucceeded {
  type: LoginActionTypes.SET_NEW_PASSWORD_SUCCEEDED;
}
export interface SignOutRequest {
  type: LoginActionTypes.SIGN_OUT_REQUEST;
}
export interface SignOutSucceeded {
  type: LoginActionTypes.SIGN_OUT_SUCCEEDED;
}
export interface SetStep {
  type: LoginActionTypes.SET_STEP;
  step: LoginSteps;
}

// login steps
export interface InitialForm {
  type: LoginActionTypes.INITIAL_FORM;
}
export interface EmailRegistrationForm {
  type: LoginActionTypes.EMAIL_REGISTRATION_FORM;
}
export interface PasswordRegistrationForm {
  type: LoginActionTypes.PASSWORD_REGISTRATION_FORM;
}
export interface CodeRegistrationForm {
  type: LoginActionTypes.CODE_REGISTRATION_FORM;
  result: {};
}
export interface LoginForm {
  type: LoginActionTypes.LOGIN_FORM;
}
export interface ForgotPasswordForm {
  type: LoginActionTypes.FORGOT_PASSWORD_FORM;
}
export interface SendCodeForNewPasswordModal {
  type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_FORM;
  result: {};
}
// close && open modal
export interface OpenModal {
  type: LoginActionTypes.OPEN_MODAL;
}
export interface CloseModal {
  type: LoginActionTypes.CLOSE_MODAL;
}

export function signupEmailRequest(username: string): SignupEmailRequest {
  return {
    type: LoginActionTypes.SIGNUP_EMAIL_REQUEST,
    username,
  };
}
export function signupPasswordRequest(
  username: string,
  password: string,
  passwordRepeat: string,
): SignupPasswordRequest {
  return {
    type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST,
    username,
    password,
    passwordRepeat,
  };
}
export function signupConfirmCode(username: string, confirmCode: string): SignupConfirmCode {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE,
    username,
    confirmCode,
  };
}
export function signupSucceeded(result: {}): SignupSucceeded {
  console.log('result from action', result);
  return {
    type: LoginActionTypes.SIGNUP_SUCCEEDED,
    result,
  };
}
export function signupFailed(error: Error): SignupFailed {
  return {
    type: LoginActionTypes.SIGNUP_FAILED,
    error,
  };
}
export function signupConfirmCodeFailed(error: Error): SignupConfirmCodeFailed {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED,
    error,
  };
}
export function signinRequest(username: string, password: string): SigninRequest {
  return {
    type: LoginActionTypes.SIGNIN_REQUEST,
    username,
    password,
  };
}
export function signinSucceeded(result: {}): SigninSucceeded {
  return {
    type: LoginActionTypes.SIGNIN_SUCCEEDED,
    result,
  };
}
export function signinFailed(error: Error): SigninFailed {
  return {
    type: LoginActionTypes.SIGNIN_FAILED,
    error,
  };
}
export function forgotPassword(username: string): ForgotPassword {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_REQUEST,
    username,
  };
}
export function forgotPasswordFailed(error: Error): ForgotPasswordFailed {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_FAILED,
    error,
  };
}
export function sendCodeForNewPassword(confirmCode: string): SendCodeForNewPassword {
  return {
    type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_REQUEST,
    confirmCode,
  };
}
export function setNewPassword(username: string, confirmCode: string, newPassword: string): SetNewPassword {
  return {
    type: LoginActionTypes.SET_NEW_PASSWORD_REQUEST,
    username,
    confirmCode,
    newPassword,
  };
}
export function setNewPasswordSucceeded(): SetNewPasswordSucceeded {
  return {
    type: LoginActionTypes.SET_NEW_PASSWORD_SUCCEEDED,
  };
}
export function signOutRequest(): SignOutRequest {
  return {
    type: LoginActionTypes.SIGN_OUT_REQUEST,
  };
}
export function signOutSucceeded(): SignOutSucceeded {
  return {
    type: LoginActionTypes.SIGN_OUT_SUCCEEDED,
  };
}
export function setStep(step: LoginSteps): SetStep {
  return {
    type: LoginActionTypes.SET_STEP,
    step,
  };
}
// login steps
export function initialForm(): InitialForm {
  return {
    type: LoginActionTypes.INITIAL_FORM,
  };
}
export function emailRegistrationForm(): EmailRegistrationForm {
  return {
    type: LoginActionTypes.EMAIL_REGISTRATION_FORM,
  };
}
export function passwordRegistrationForm(): PasswordRegistrationForm {
  return {
    type: LoginActionTypes.PASSWORD_REGISTRATION_FORM,
  };
}
export function codeRegistrationForm(result: {}): CodeRegistrationForm {
  return {
    type: LoginActionTypes.CODE_REGISTRATION_FORM,
    result,
  };
}
export function loginForm(): LoginForm {
  return {
    type: LoginActionTypes.LOGIN_FORM,
  };
}
export function forgotPasswordForm(): ForgotPasswordForm {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_FORM,
  };
}
export function sendCodeForNewPasswordModal(result: {}): SendCodeForNewPasswordModal {
  return {
    type: LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_FORM,
    result,
  };
}
// close && open modal
export function openModal(): OpenModal {
  return {
    type: LoginActionTypes.OPEN_MODAL,
  };
}
export function closeModal(): CloseModal {
  return {
    type: LoginActionTypes.CLOSE_MODAL,
  };
}
